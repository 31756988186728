import React, { useState } from "react";

const AddPost = ({ createPost }) => {
  const [postTitle, setPostTitle] = useState("");
  const [postDesc, setPostDesc] = useState("");

  return (
    <div>
      <div className="my-5">
        <h1>Add New Post</h1>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          createPost({ title: postTitle, body: postDesc });
        }}
      >
        <div className="mb-3">
          <label htmlFor="postTitle" className="form-label">
            Post Title
          </label>
          <input type="text" id="postTitle" value={postTitle} className="form-control" onChange={(e) => setPostTitle(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="postTitle" className="form-label">
            Post Description
          </label>
          <textarea type="text" id="postTitle" value={postDesc} style={{ height: "150px" }} className="form-control" onChange={(e) => setPostDesc(e.target.value)} />
        </div>
        <button className="btn btn-primary w-100" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default AddPost;
