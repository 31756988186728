import React from "react";

const About = () => {
  return (
    <div className="my-5">
      <h1>This is About Page</h1>
    </div>
  );
};

export default About;
