import React from "react";
import { Link } from "react-router-dom";

const Navbar = ({ search, setSearch }) => {
  return (
    <>
      <div className="row bg-info">
        <div className="col-12 d-flex justify-content-start align-items-center px-5">
          <Link to="/" style={{ textDecoration: "none", color: "#000" }}>
            <h1>Blog App</h1>
          </Link>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <form className="d-flex" role="search" onSubmit={(e) => e.preventDefault()}>
            <input className="form-control" type="search" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
          </form>
          <div className="navbar-nav d-flex flex-row">
            <Link className="nav-link px-3" to="/">
              Home
            </Link>
            <Link className="nav-link px-3" to="/create-post">
              Post
            </Link>
            <Link className="nav-link px-3" to="/about">
              About
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
