import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import About from "./Components/About";
import Missing from "./Components/Missing";
import PostPage from "./Components/Post/PostPage";
import AddPost from "./Components/Post/AddPost";
import EditPost from "./Components/Post/EditPost";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

function App() {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [postData, setPostData] = useState([]);
  const baseUrl = process.env.REACT_APP_SAMPLE_BLOG_URL;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(baseUrl);
        setPostData(response.data);
        console.log(response);
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    };

    (async () => fetchPosts())();
  }, [baseUrl]);

  useEffect(() => {
    const filteredResults = postData.filter((post) => post.body.toLowerCase().includes(search.toLowerCase()) || post.title.toLowerCase().includes(search.toLowerCase()));
    setSearchResults(filteredResults);
  }, [postData, search]);

  const handleUpdate = async (postItem) => {
    if (window.confirm("Save Changes ?")) {
      const listPostItems = postData.map((post) => (post.id === postItem.id ? { ...post, title: postItem.title, body: postItem.body } : post));
      setPostData(listPostItems);

      const thePost = { id: postItem.id, title: postItem.title, datetime: new Date(), body: postItem.body };

      try {
        const response = await axios.put(`${baseUrl}/${postItem.id}`, thePost);
        if (response && response.data) {
          window.alert("Update Success");
        }
      } catch (error) {
        window.alert(error.response ? error.response.data : error.message);
      }

      window.location.href = "/";
    }
  };

  const handleAdd = async (postItem) => {
    if (window.confirm("Add Post ?")) {
      const id = +new Date();
      const newPost = { id, title: postItem.title, datetime: new Date(), body: postItem.body };
      const postItemList = [...postData, newPost];
      setPostData(postItemList);

      try {
        const response = await axios.post(baseUrl, newPost);
        if (response && response.data) {
          window.alert("New Post Published!");
        }
      } catch (error) {
        window.alert(error.reponse ? error.response.data : error.message);
      }

      window.location.href = "/";
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Delete Post ?")) {
      const otherPost = postData.filter((x) => x.id !== id);
      setPostData(otherPost);

      try {
        const response = await axios.delete(`${baseUrl}/${id}`);
        if (response && response.data) {
          window.alert("Post Deleted!");
        }
      } catch (error) {
        window.alert(error.response ? error.response.data : error.message);
      }

      window.location.href = "/";
    }
  };

  return (
    <Router>
      <Navbar search={search} setSearch={setSearch} />
      <div className="px-5 my-5">
        <Routes>
          <Route path="/" element={<Home postData={searchResults} />} />
          <Route path="/create-post" element={<AddPost createPost={handleAdd} />} />
          <Route path="/post/:id" element={<PostPage postData={postData} deletePost={handleDelete} />} />
          <Route path="/modify-post/:id" element={<EditPost postData={postData} updatePost={handleUpdate} />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Missing />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
