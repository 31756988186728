import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

const EditPost = ({ postData, updatePost }) => {
  const { id } = useParams();
  const post = postData.find((x) => x.id === parseInt(id));
  const [postTitle, setPostTitle] = useState(post ? post.title : "");
  const [postDesc, setPostDesc] = useState(post ? post.body : "");

  return (
    <div>
      <div className="my-5">
        <h1>Edit Post</h1>
        <Link to={`/post/${post.id}`} className="btn btn-primary">
          Back
        </Link>
      </div>

      {post && (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updatePost({ id: post.id, title: postTitle, body: postDesc });
          }}
        >
          <div className="mb-3">
            <label htmlFor="postTitle" className="form-label">
              Post Title
            </label>
            <input type="text" id="postTitle" value={postTitle} className="form-control" onChange={(e) => setPostTitle(e.target.value)} />
          </div>
          <div className="mb-3">
            <label htmlFor="postTitle" className="form-label">
              Post Description
            </label>
            <textarea type="text" id="postTitle" value={postDesc} style={{ height: "150px" }} className="form-control" onChange={(e) => setPostDesc(e.target.value)} />
          </div>
          <button className="btn btn-primary w-100" type="submit">
            Save
          </button>
        </form>
      )}
    </div>
  );
};

export default EditPost;
