import React from "react";

const Footer = () => {
  return (
    <nav className="navbar fixed-bottom bg-info">
      <div className="container-fluid d-flex justify-content-center align-items-center">
        <h5>Copyright&copy; 2022</h5>
      </div>
    </nav>
  );
};

export default Footer;
