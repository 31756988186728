import React from "react";
import { useParams, Link } from "react-router-dom";

const PostPage = ({ postData, deletePost }) => {
  const { id } = useParams();
  const post = postData.find((x) => x.id === parseInt(id));

  return (
    <div>
      {post ? (
        <div>
          <h1>{post.title}</h1>
          <p>{post.body}</p>
          <Link className="btn btn-primary me-2" to={`/modify-post/${post.id}`}>
            Edit Post
          </Link>
          <button
            className="btn btn-danger"
            onClick={() => {
              deletePost(post.id);
            }}
          >
            Delete Post
          </button>
        </div>
      ) : (
        <div>
          <h1>Post Not Found</h1>
        </div>
      )}
    </div>
  );
};

export default PostPage;
