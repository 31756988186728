import React from "react";
import { Link } from "react-router-dom";

const PostList = ({ postData }) => {
  return (
    <div>
      {postData.length > 0 &&
        postData.map((post) => (
          <Link key={post.id} to={`/post/${post.id}`} style={{ textDecoration: "none", color: "#000" }}>
            <div>
              <h1>{post.title}</h1>
              <h5>{post.datetime}</h5>
              <h3>{post.body}</h3>
              <hr />
            </div>
          </Link>
        ))}
    </div>
  );
};

export default PostList;
